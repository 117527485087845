.game {
    display: block;
    text-align: center;
    
    .game-button {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    
    @media (max-width: 960px) {
        .sticky {
            position: -webkit-sticky;
            position: sticky;
            bottom: 0;
        }
    }
}
