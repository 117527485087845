@import '../../../variables.scss';

.game-status {
    font-size: 1.2em;
    
    &.mobile-status {
        background-color: $card-bg;
        padding-bottom: 20px;
    }

    button {
        margin-top: 0.5rem;
    }
    
    .text {
        font-weight: bold;
    }
}
