.card {
    .content-card {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: 6rem;
        max-width: 100vw;
        display: flex;
        align-items: center;
        overflow-wrap:break-word;
        
        > span {
            min-width: 0;
        }
    }
    
    .word {
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        font-size: 1.5rem;
        
        &.invisible {
            display: none;
        }
    }
    
    .selectable {
        cursor: pointer;
    }
} 
