.clue-list {

    padding-right: 1rem;
    padding-left: 1rem;

    .table {
        margin-top: 0.5rem;
    }

    .cell {
        &.word {
            overflow-wrap: break-word;
            width: 45%
        }
        
        &.number {
            width: 5%;
        }
    }
}

