@import '../../../variables.scss';

.bottom-row {
  background-color: $app-bg;
  
  .desktop {
    > div {
      max-width: none;
    }
  }
  
  .mobile {
    margin-top: 5px;
    
    .nav {
      margin-top: 5px;
      background-color: $card-bg;
    }
  }
  
  .navigation.Mui-selected {
    &.default {
      color: $neutral;
      
      &.cb {
        color: $neutral-cb;
      }
    }

    &.primary {
      color: $blue;
      
      &.cb {
        color: $blue-cb;
      }
    }

    &.secondary {
      color: $red;
      
      &.cb {
        color: $red-cb;
      }
    }
  }
}

@media (min-width: 960px) {
  .bottom-row .content-card {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}

@media (min-width: 1280px) {
  .bottom-row .content-card {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
}
