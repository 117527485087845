.game-list {
    h2 {
        font-weight: bold;
        font-size: 1.5rem;
    }
    
    td {
        border-bottom: none;
    }
    
    .game-name {
        font-size: 1.4rem !important;
    }
    
    .players-amount {
        font-size: 1.5rem !important;
    }
    
    .join-game {
        font-size: 0.8rem !important;
    }
}

