.create-game-form {
  .theme-select {
    min-width: 50%;
  }
  
  .word-input {
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 1rem;
    resize: vertical;
  }
}
