@import './variables.scss';

body {
    font-family: Helvetica, serif;
    background: $app-bg;
    margin: 0;
}

.content-card {
    background: $card-bg;
    border-radius: 20px;
    text-align: center;

    padding-top: 1rem;
    padding-bottom: 1rem;
    
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

@media (min-width: 960px) {
    .content-card {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }
}

h2, h1 {
    text-align: center;
    margin: 0;
}

form {
    width: 100%;
}

table {
    table-layout: fixed;
}

.white-text {
    color: $card-bg;
}

.red {
    &.text {
        color: $red;
        &.cb {
            color: $red-cb;
        }
    }
    &:not(.text) {
        background-color: $red;
        &.cb {
            background-color: $red-cb;
        }
    }
}

.blue {
    &.text {
        color: $blue;
        &.cb {
            color: $blue-cb;
        }
    }
    &:not(.text) {
        background-color: $blue;
        &.cb {
            background-color: $blue-cb;
        }
    }
}

.neutral, .spec {
    &.text {
        color: $neutral;
        &.cb {
            color: $neutral-cb;
        }
    }
    &:not(.text) {
        background-color: $neutral;
        &.cb {
            background-color: $neutral-cb;
        }
    }
}

.black {
    &.text {
        color: $black;
        &.cb {
            color: $black-cb;
        }
    }
    &:not(.text) {
        background-color: $black;
        &.cb {
            background-color: $black-cb;
        }
    }
}

.MuiButton-contained.neutral-button {
    color: #fff;
    background-color: $neutral;
    
    &:hover {
        background-color: $neutral-dark;
    }
}

.MuiButton-contained.black-button {
    color: #fff;
    background-color: $black;

    &:hover {
        background-color: $black-light;
    }
}