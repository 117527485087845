﻿$app-bg: #282c34;
$card-bg: beige;
$red: #ff7675;
$red-cb: #ff7675;
$blue: #5b7ab4;
$blue-cb: #5b7ab4;
$neutral: #c29161;
$neutral-dark: #8f6336;
$neutral-cb: #7fc261;
$black: #4b4f51;
$black-cb: #4b4f51;
$black-light: #777b7d;
